<template>
  <div class="login-background">
    <AppLoadingSpinner v-model="isLoading" />
    <div class="login-panel">
      <v-card class="desc-panel" color="primary" elevation="6" rounded="0">
        <h1>欢迎使用</h1>
        <h2>
          {{ loginPanelTitle }}
        </h2>
        <img src="../assets/undraw_settings_tab_mgiw.svg" />
      </v-card>
      <v-card class="input-panel" rounded="0">
        <h1>管理登录</h1>
        <v-form ref="loginform" @submit.prevent="userLogin">
          <v-text-field
            dense
            autofocus
            label="请输入用户名"
            prepend-icon="mdi-account"
            v-model="loginform.username"
          ></v-text-field>
          <v-text-field
            dense
            label="请输入密码"
            :type="isShowPassword ? 'text' : 'password'"
            prepend-icon="mdi-key"
            :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="loginform.password"
            @click:append="isShowPassword = !isShowPassword"
          ></v-text-field>
          <div class="input-confirm">
            <v-btn
              type="submit"
              depressed
              block
              color="primary"
              class="my-2"
              :loading="isLoginLoading"
              :disabled="isLoginLoading"
            >
              登 录
            </v-btn>
            <v-btn
              depressed
              block
              color="primary"
              class="my-5"
              @click="goToSca"
            >
              直接进入测量中心
              <v-icon small class="ml-2">mdi-fountain-pen-tip</v-icon>
            </v-btn>
          </div>
          <div class="app-version">{{ userCustInfo.appVersion }}</div>
        </v-form>
      </v-card>
    </div>
    <AppDialog
      v-model="isShowAddUserDialog"
      size="small"
      title="添加新的账号"
      text-class="px-10"
      color="success"
      action-text="添加"
      :loading="isBtnLoading"
      @confirm="addUserConfirmed"
      @closed="addUserClosed"
    >
      <v-text-field
        label="账户显示名称"
        v-model="userCustInfo.displayName"
        :rules="fieldRules.name"
      ></v-text-field>
      <v-text-field
        label="登录名"
        v-model="userInfo.loginKey"
        :rules="fieldRules.name"
      ></v-text-field>
      <v-text-field
        label="登录密码"
        v-model="userInfo.loginPwd"
        :type="isShowPassword ? 'text' : 'password'"
        :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="fieldRules.pwd"
      ></v-text-field>
      <v-text-field
        label="确认登录密码"
        v-model="userInfo.loginPwdConfirm"
        :type="isShowPassword ? 'text' : 'password'"
        :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="fieldRules.pwd"
      ></v-text-field>
      <v-select
        v-model="userInfo.roleName"
        :items="allRoles"
        item-text="name"
        item-value="name"
        label="账户角色"
        single-line
        :rules="fieldRules.roleName"
      ></v-select>
    </AppDialog>
    <AppMessageBox v-model="loginMessage" title="登录时发生错误" />
  </div>
</template>

<script>
import AppMessageBox from "@/components/AppMessageBox";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import {
  userAuth,
  fetchSingletonCustInfo,
  fetchUserCustInfo,
  initNewUser,
  initAndGetAllRoles
} from "@/api/user";
import { reloadUserRoutes } from "@/router";

export default {
  components: {
    AppMessageBox,
    AppLoadingSpinner,
    AppDialog
  },

  data() {
    return {
      isLoading: false,
      isLoginLoading: false,
      isBtnLoading: false,
      loginform: {
        username: "",
        password: ""
      },
      isShowPassword: false,
      loginMessage: "",
      isShowAddUserDialog: false,
      userCustInfo: {
        displayName: "",
        description: "",
        website: "",
        landingTitle: "",
        isSingleton: true
      },
      userInfo: {
        loginKey: "",
        loginPwd: "",
        loginPwdConfirm: "",
        roleName: ""
      },
      allRoles: [],
      fieldRules: {
        name: [
          val => (val || "").length > 0 || "不能为空",
          val => (val || "").length < 20 || "不能超过20个字符"
        ],
        pwd: [val => (val || "").length > 5 || "密码不能少于6个字符"],
        roleName: [val => (val || "").length > 0 || "账户角色不能为空"]
      }
    };
  },

  computed: {
    loginPanelTitle() {
      if (this.userCustInfo.landingTitle) {
        return this.userCustInfo.landingTitle;
      } else if (this.userCustInfo.displayName) {
        return this.userCustInfo.displayName;
      } else if (!this.userCustInfo.isSingleton) {
        // 多用户的情况下才叫这个名字
        return "心理健康测评系统 3.0";
      }
      return "";
    }
  },

  methods: {
    async loadCustInfoIfSingleton() {
      try {
        this.isLoading = true;
        this.userCustInfo = await fetchSingletonCustInfo();
      } catch (err) {
        this.loginMessage = err.message;
      }
      this.isLoading = false;
    },
    // ========================= Login ================================
    async userLogin() {
      if (this.loginform.username && this.loginform.password) {
        this.isLoginLoading = true;
        try {
          let authRes = await userAuth(
            this.loginform.username,
            this.loginform.password
          );
          this.$store.dispatch("user/setLoginInfo", {
            regionGuid: authRes.regionGuid,
            loginNodeGuids: authRes.loginNodeGuids,
            userGuid: authRes.userGuid,
            userName: authRes.userName || ""
          });
          reloadUserRoutes(
            async () => {
              await this.assignCustomerInfo(authRes.regionGuid);
              this.$router.push({ name: "admin" });
              this.isLoading = true;
            },
            err => {
              this.loginMessage = err;
              throw "Router加载失败";
            }
          );
        } catch (err) {
          this.loginMessage = err.message;
        }
        this.isLoginLoading = false;
      }
    },
    async assignCustomerInfo(custGuid) {
      let custInfo = await fetchUserCustInfo(custGuid);
      this.$store.dispatch("user/setUserDispName", custInfo.displayName);
    },
    // ========================= Add new Customer ================================
    async getAllRoles() {
      try {
        this.allRoles = await initAndGetAllRoles();
      } catch (err) {
        this.loginMessage = err.message;
      }
    },
    createNewUser() {
      return initNewUser({
        loginKey: this.userInfo.loginKey,
        loginPwd: this.userInfo.loginPwd,
        displayName: this.userCustInfo.displayName,
        roleName: this.userInfo.roleName
      });
    },
    goToSca() {
      //从.env里面获取SCA登录界面的路径
      var newPath = process.env.VUE_APP_SCA_PATH;
      window.open(newPath, "_blank");
    },
    bindKeyCombo(e) {
      if (e.ctrlKey && e.altKey && (e.key === "o" || e.key === "O")) {
        this.$nextTick(() => {
          this.openAddUserDialog();
        });
        e.preventDefault();
      }
    },
    async openAddUserDialog() {
      // 单用户情况下，也就是 isSingleton 的情况下，无法添加 customer
      if (
        process.env.VUE_APP_NEW_CUST_VISIBLE === "true" &&
        !this.userCustInfo.isSingleton
      ) {
        this.isLoading = true;
        await this.getAllRoles();
        this.isLoading = false;
        this.isShowAddUserDialog = true;
      }
    },
    async addUserConfirmed() {
      if (this.userInfo.loginPwd !== this.userInfo.loginPwdConfirm) {
        this.loginMessage = "两次输入密码不一致";
        return;
      }
      this.isBtnLoading = true;
      try {
        await this.createNewUser();
        this.addUserClosed();
      } catch (err) {
        this.loginMessage = err.message;
      }
      this.isBtnLoading = false;
    },
    addUserClosed() {
      this.isShowAddUserDialog = false;
      this.$nextTick(() => {
        this.userCustInfo = {
          displayName: ""
        };
        this.userInfo = {
          loginKey: "",
          loginPwd: "",
          loginPwdConfirm: "",
          roleName: ""
        };
      });
    }
  },

  created() {
    document.onkeydown = this.bindKeyCombo;
    this.loadCustInfoIfSingleton();
  }
};
</script>

<style scoped lang="scss">
.login-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    -45deg,
    $background-light-gray,
    $background-white
  );
  .login-panel {
    position: relative;
    margin: 100px auto;
    width: 900px;
    height: 500px;
    background-color: transparent;
  }
}

/* Panels */
.desc-panel {
  position: absolute;
  top: 30px;
  bottom: 30px;
  left: 110px;
  width: 420px;
  z-index: 2;
  padding: 40px 0 0 50px;
  // background: linear-gradient(-45deg, $background-medium, $background-light);
  box-shadow: $box-shadow-medium;
  color: $font-color-white;
  h1 {
    font-size: 28px;
  }
  h2 {
    margin: 20px 55px 40px 0;
    font-size: 22px;
    font-weight: normal;
  }
  img {
    width: 350px;
  }
}
.app-version {
  text-align: right;
  font-size: 6px;
  color: #bbb;
}

/* Input Panel*/
.input-panel {
  position: absolute;
  top: 70px;
  bottom: 70px;
  right: 0;
  width: 380px;
  z-index: 1;
  padding: 0 60px;
  background-color: $background-white;
  border-radius: 0 10px 10px 0;
  color: $font-color-black;
  h1 {
    height: 110px;
    line-height: 110px;
    text-align: center;
    font-size: 22px;
    font-weight: normal;
  }
  .input-confirm {
    text-align: center;
  }
}

/* Login Alert */
.login-alert {
  position: absolute;
  bottom: 14px;
  right: 0;
  width: 370px;
  z-index: 0;
  padding: 8px;
}

@keyframes options-panel-move {
  0% {
    left: 300px;
  }
  100% {
    left: 0;
  }
}
@keyframes desc-panel-move {
  0% {
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    left: 130px;
  }
}
@keyframes input-panel-move {
  0% {
    right: 300px;
  }
  100% {
    right: 0;
  }
}
</style>
